import { Link } from 'gatsby';
import React, { FC, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as styles from './base-navigation.module.css';
import Logo from '../../../../assets/images/alchem-logo.svg';

gsap.registerPlugin(ScrollTrigger);

export const BaseNavigation: FC = () => {
    useEffect(() => {
        const showHideableNav = gsap
            .to('#hideable-nav', {
                yPercent: 100,
                paused: true,
                duration: 0.2,
                ease: 'power2',
            })
            .progress(0);

        ScrollTrigger.create({
            trigger: document.body,
            markers: false,
            onUpdate: ({ direction, trigger }) => {
                if (trigger) {
                    // Calculate if scrolling position is in "safe zone", i.e. where the two navs can't overlap
                    const isInSafeZone = Math.abs(trigger.getBoundingClientRect().top) > 300;

                    // Only pull the nav down when scrolling up inside safe zone
                    if (isInSafeZone && direction === -1) {
                        showHideableNav.play();
                    } else {
                        showHideableNav.reverse();
                    }
                }
            },
        });
    }, []);

    return (
        <nav id="header" className={styles.BaseNavigation}>
            <div className="container h-20 flex items-center justify-between">
                <div className="w-1/5">
                    <Link className="hover:no-underline font-extrabold text-xl" to="/">
                        <Logo className="max-w-full" />
                    </Link>
                </div>

                <div className="w-4/5">
                    <ul className="flex gap-6 justify-end items-center font-semibold">
                        <li>
                            <a className="" href="/#">
                                Hallo
                            </a>
                        </li>
                        <li>
                            <a className="" href="/#">
                                Test
                            </a>
                        </li>
                        <li>
                            <a className="" href="/#">
                                Cool
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="block lg:hidden pr-4">
                    <button
                        className="flex items-center px-3 py-2 border rounded appearance-none focus:outline-none"
                        type="button"
                    >
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
            </div>

            <div
                id="hideable-nav"
                className="fixed top-0 w-full -translate-y-full z-50 bg-alchem-semidark bg-opacity-80 backdrop-blur-md"
            >
                <div className="container h-20 flex items-center justify-between">
                    <div className="w-1/5">
                        <Link className="hover:no-underline font-extrabold text-xl" to="/">
                            <Logo className="max-w-full" />
                        </Link>
                    </div>

                    <div className="w-4/5">
                        <ul className="flex gap-6 justify-end items-center font-semibold">
                            <li>
                                <a className="" href="/#">
                                    Hallo
                                </a>
                            </li>
                            <li>
                                <a className="" href="/#">
                                    Test
                                </a>
                            </li>
                            <li>
                                <a className="" href="/#">
                                    Cool
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="block lg:hidden pr-4">
                        <button
                            className="flex items-center px-3 py-2 border rounded appearance-none focus:outline-none"
                            type="button"
                        >
                            <svg
                                className="fill-current h-3 w-3"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <p>Menu</p>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import { SiteMetadataQuery } from '@generated/graphql';
import { useGlobalPage } from '../../providers/global-page';

export interface HeaderProps {
    title?: string;
}

export const HTMLHead: FC<HeaderProps> = ({ title }) => {
    const { site } = useStaticQuery<SiteMetadataQuery>(graphql`
        query SiteMetadata {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    const { preferredColorMode } = useGlobalPage();

    return (
        <>
            <Helmet>
                <title>{title ?? site?.siteMetadata?.title}</title>
                <link
                    rel="shortcut icon"
                    type="image/x-icon"
                    href={
                        preferredColorMode === 'light' ? 'logo/Icon_Positive_Mono.svg' : 'logo/Icon_Negative_Mono.svg'
                    }
                />
            </Helmet>
        </>
    );
};

import React, { FC } from 'react';
import { FaBehance, FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';

export const BaseFooter: FC = () => {
    return (
        <footer className="mt-28 py-12 bg-alchem-dark">
            <div className="container flex lg:flex-row flex-col gap-12">
                <div className="lg:w-1/4 w-full">
                    <h2 className="hl2">Kontakt</h2>
                    <p className="mb-4">
                        ALCHEMISTEN AG <br />
                        Heinrich-Renken-Straße 1 <br />
                        26127 Oldenburg
                    </p>
                    <p>
                        Tel. 0 44 1 – 95 50 2-0 <br />
                        Fax 0 44 1 – 95 50 2 -20 <br />
                        zentrale@alchemisten.de
                    </p>
                </div>
                <div className="lg:w-1/4 w-full">
                    <h2 className="hl2">Seiten</h2>
                    <p className="mb-4">
                        ALCHEMISTEN AG <br />
                        Heinrich-Renken-Straße 1 <br />
                        26127 Oldenburg
                    </p>
                    <p>
                        Tel. 0 44 1 – 95 50 2-0 <br />
                        Fax 0 44 1 – 95 50 2 -20 <br />
                        zentrale@alchemisten.de
                    </p>
                </div>
                <div className="lg:w-1/4 w-full">
                    <h2 className="hl2">Social</h2>
                    <div className="grid lg:grid-cols-4 grid-cols-4 gap-4">
                        <div className="bg-alchem-semidark p-4">
                            <FaLinkedinIn className="w-full h-full" />
                        </div>
                        <div className="bg-alchem-semidark p-4">
                            <FaFacebookF className="w-full h-full" />
                        </div>
                        <div className="bg-alchem-semidark p-4">
                            <FaInstagram className="w-full h-full" />
                        </div>
                        <div className="bg-alchem-semidark p-4">
                            <FaTwitter className="w-full h-full" />
                        </div>
                        <div className="bg-alchem-semidark p-4">
                            <FaBehance className="w-full h-full" />
                        </div>
                        <div className="bg-alchem-semidark p-4">
                            <FaYoutube className="w-full h-full" />
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/4 w-full">
                    <h2 className="hl2">Wir sind offizieller Sponsor</h2>
                    <div>
                        <p>Oldenburg Knights</p>
                    </div>
                    <div>
                        <p>OCM</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};
